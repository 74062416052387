import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { SxProps } from "@mui/system";
import {
  HiMiniArrowTopRightOnSquare,
  HiMiniCheck,
  HiMiniEllipsisVertical,
  HiOutlineBookOpen,
  HiOutlineCheck,
  HiOutlineChevronDown,
  HiOutlineDocumentText,
  HiOutlineFolder,
  HiOutlineInformationCircle,
  HiOutlineLockClosed,
  HiOutlinePaperAirplane,
  HiOutlinePaperClip,
  HiOutlinePencil,
  HiOutlineTrash,
  HiOutlineUser,
  HiOutlineUsers,
} from "react-icons/hi2";
import {
  BsFileEarmarkText,
  BsFiletypeDoc,
  BsFiletypeJpg,
  BsFiletypePdf,
  BsFiletypeXls,
} from "react-icons/bs";
import { toast } from "react-toastify";
import api from "./api/api";
import { DataInput } from "./DataInput";
import { Input } from "./Input";
import moment from "moment";
import { ModalDelete } from "./ModalDelete";
import { Skeleton, Tooltip, filledInputClasses } from "@mui/material";
import { Modal } from "./Modal";
import {
  HiCloudUpload,
  HiOutlineClipboardCopy,
  HiOutlineCloudUpload,
  HiOutlineMail,
  HiOutlineSearch,
  HiOutlineUpload,
} from "react-icons/hi";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { ModalSM } from "./ModalSM";
import { useNavigate } from "react-router-dom";
import { PiSignature } from "react-icons/pi";
import { ModalXL } from "./ModalXL";

function getMimeType(fileExtension) {
  // Ensure the file extension is prefixed with a dot.
  /*     const extension = fileExtension.startsWith('.') ? fileExtension.toLowerCase() : `.${fileExtension.toLowerCase()}`; */

  // Define a mapping of file extensions to MIME types.
  const mimeTypes = {
    "image/jpeg": "jpeg",
    "image/webp": "jpeg",
    "image/png": "jpeg",
    "image/gif": "jpeg",
    "application/pdf": "pdf",
    "application/msword": "docx",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "docx",
    "application/vnd.google-apps.document": "docx",
    "application/pages": "docx",
    "application/vnd.ms-excel": "xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/vnd.google-apps.spreadsheet": "xlsx",
    "text/plain": "docx",
    "text/csv": "xlsx",
    "application/vnd.google-apps.folder": "folder",
  };

  // Return the corresponding MIME type or a default value if not found.
  return mimeTypes[fileExtension] || "null";
}

const getMime = (mime) => {
  let type = "";
  switch (getMimeType(mime)) {
    case "jpeg":
      type = <BsFiletypeJpg className=" icon-documentazioni-new" />;
      break;
    case "pdf":
      type = <BsFiletypePdf className="icon-documentazioni-new" />;
      break;
    case "docx":
      type = <BsFiletypeDoc className="icon-documentazioni-new" />;
      break;
    case "xlsx":
      type = <BsFiletypeXls className="icon-documentazioni-new" />;
      break;
    case "folder":
      type = <HiOutlineFolder className="icon-documentazioni-new" />;
      break;
    default:
      type = <BsFileEarmarkText className="icon-documentazioni-new" />;
      break;
  }
  return type;
};

export const ComponentDocumentazione = ({
  operazione,
  doc,
  sinistro,
  persone,
  getDoc,
  setTab,
}) => {
  const navigate = useNavigate();
  const [documentazione, setDocumentazione] = useState(null);
  const [folder, setFolder] = useState(null);
  const [bytes, setBytes] = useState(0);
  const [selectedBytes, setSelectedBytes] = useState(0);
  const [files, setFiles] = useState([]);
  const [filesBk, setFilesBk] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileSelected, setFileSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [renamingFolder, setRenamingFolder] = useState(false);
  const [saving, setSaving] = useState(false);

  const [openModalSelect, setOpenModalSelect] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [emailType, setEmailType] = useState("");
  const [testoMail, setTestoMail] = useState([]);

  const [search, setSearch] = useState("");

  const handleSearchChange = (event) => {
    setSearch(event.target.value);

    if (event.target.value === "") return setFiles([...filesBk]);

    const filteredFiles = filesBk?.filter((file) =>
      file.name?.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFiles(filteredFiles);
  };

  const handleCheckbox = () => {
    setSelectAll((prev) => !prev);
    if (selectAll) {
      setFileSelected([]);
    } else {
      setFileSelected(
        files?.filter(
          (f) => f?.mimeType !== "application/vnd.google-apps.folder"
        )
      );
    }
  };
  const handleChange = (e) => {
    setDocumentazione({ ...documentazione, [e.target.name]: e.target.value });
  };
  const handleChangeUppercase = (e) => {
    setDocumentazione({
      ...documentazione,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };
  const handleRename = async () => {
    if (file?.name) {
      try {
        await api.post("documentazioni/rename/", {
          file: file,
        });
        setOpenModal(false);
        toast.success("Rinomina effettuata con successo!");
        setTimeout(() => {
          getFiles();
        }, 1000);
      } catch (error) {
        toast.error("Errore durante la rinomina!");
      }
    } else {
      toast.error("Inserire nome file!");
    }
  };
  const handleRenameFolder = async () => {
    if (documentazione.nome) {
      try {
        await api.post("documentazioni/renameFolder/", {
          folder: {
            ...folder,
            name: documentazione.nome,
            idDoc: documentazione.id,
          },
        });
        setRenamingFolder(false);
        toast.success("Rinomina effettuata con successo!");
      } catch (error) {
        toast.error("Errore durante la rinomina!");
      }
    } else {
      toast.error("Inserire nome file!");
    }
  };
  const resetType = () => {
    setEmailType("");
    setOpenModalSelect(false);
    setFileSelected([]);
  };
  const handleSave = async () => {
    if (documentazione?.data) {
      setSaving(true);
      try {
        let response = await api.post("documentazioni", {
          documentazione: {
            ...documentazione,
            id_sinistro: sinistro?.id,
            folderName:
              sinistro.amministratore +
              " " +
              moment(sinistro.data).format("YYYY"),
          },
        });
        getDoc();
        toast.success("Cartella Documentazione creata!");
      } catch (error) {
        toast.error("Errore durente la creazione della cartella!");
      }
      setSaving(false);
    } else {
      toast.error("Inserire data!");
    }
  };

  function formatBytes(bytes) {
    if (typeof bytes !== "number" || bytes < 0) {
      throw new Error("Input must be a non-negative number");
    }
    const units = ["bytes", "KB", "MB"];
    let unitIndex = 0;

    while (bytes >= 1024 && unitIndex < units.length - 1) {
      bytes /= 1024;
      unitIndex++;
    }

    return `${bytes.toFixed(2)} ${units[unitIndex]}`;
  }

  const getFiles = async () => {
    setLoadingFiles(true);
    const urlParams = new URLSearchParams();
    urlParams.append("cartella", doc?.id_folder);
    let responseDocumenti = await api.get("documentazioni/files", {
      params: urlParams,
    });
    setFolder({
      link: "https://drive.google.com/drive/folders/" + doc?.id_folder,
      id: doc?.id_folder,
    });
    setFiles(responseDocumenti.data.files);

    const folderWeight = responseDocumenti.data.files?.reduce((a, b) => {
      if (b.size) {
        a = a + Number(b.size);
        return a;
      } else {
        return a;
      }
    }, 0);

    setBytes(folderWeight);
    setFilesBk(responseDocumenti.data.files);
    setLoadingFiles(false);
  };

  const handleOpenModal = (f) => {
    setFile({ ...f });
    setOpenModal(true);
  };

  //Aggiunge files dalla selezione
  const handleAddSelect = (fileClicked) => {
    setFileSelected([...fileSelected, { ...fileClicked }]);
  };

  //Rimuove files dalla selezione
  const handleRemoveSelect = (fileClicked) => {
    let filtered = fileSelected.filter((el) => el.id !== fileClicked.id);
    setFileSelected(filtered);
  };

  const handleEmailType = async (type) => {
    let response = await api.get("tipologie_mail/" + type);
    let testo = response.data.data[0].testo;
    setTestoMail(testo);
    setEmailType(type);
  };

  useEffect(() => {
    const folderWeight = fileSelected?.reduce((a, b) => {
      if (b.size) {
        a = a + Number(b.size);
        return a;
      } else {
        return a;
      }
    }, 0);
    setSelectedBytes(folderWeight);
  }, [fileSelected]);
  useEffect(() => {
    (async () => {
      if (doc) {
        getFiles();
        setDocumentazione(doc);
      } else {
        let pers = persone;
        if (persone?.length >= 3) {
          pers = persone?.slice(0, 3);
        }
        pers =
          pers.length === 0
            ? ""
            : pers?.length > 1
            ? " " + pers?.join(" ")
            : " " + pers;
        let dan = sinistro?.danno ? " " + sinistro?.danno : "";
        setDocumentazione({
          id_sinistro: sinistro?.id,
          data: new Date(),
          nome: sinistro?.riferimento + " " + sinistro?.condominio + dan + pers,
        });
      }
    })();
  }, [doc, persone]);
  return (
    <>
      {!sinistro && (
        <h2 className="text-3xl text-center font-semibold flex-1 uppercase mb-4">
          Sinistro Rif. - {doc?.nome}
        </h2>
      )}

      <div className=" max-h-[calc(100vh_-_320px)]  lg:max-h-[calc(100vh_-_210px)] overflow-y-hidden w-full grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-2 px-4">
        <div className="col-span-3 sm:col-span-4 md:col-span-2 ">
          <DataInput
            label={"Data"}
            name="data"
            onChange={(e) =>
              setDocumentazione({
                ...documentazione,
                data: moment(e).format("YYYY-MM-DD"),
              })
            }
            value={moment(documentazione?.data)}
          />
        </div>
        <div className="col-span-3 sm:col-span-4 md:col-span-4 flex items-end">
          <div className="flex-1">
            <Input
              disabled={operazione !== "new" && !renamingFolder}
              label={"Nome Cartella"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={documentazione?.nome}
              icon={<HiOutlineFolder className="text-2xl" />}
            />
          </div>
          {operazione === "edit" && (
            <>
              {!renamingFolder ? (
                <div
                  className="icon-container-green group h-min"
                  onClick={() => setRenamingFolder(true)}
                >
                  <HiOutlinePencil className="icon-green" />
                </div>
              ) : (
                <>
                  <div
                    className="icon-container-red group h-min"
                    onClick={() => setRenamingFolder(false)}
                  >
                    <HiOutlineLockClosed className="icon-red" />
                  </div>
                  <div
                    className="icon-container-green group h-min"
                    onClick={handleRenameFolder}
                  >
                    <HiOutlineCheck className="icon-green" />
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <div className="md:col-span-6 col-span-3 sm:col-span-4 flex items-center justify-between w-full">
          <div className="flex items-end flex-1">
            <div className="w-full md:w-fit">
              <Input
                label={"Nome File"}
                type="text"
                onChange={handleSearchChange}
                value={search}
                icon={<HiOutlineSearch className="text-2xl" />}
              />
            </div>
            <div className="flex items-center h-[42px] px-3 relative text-lg">
              <input
                type="checkbox"
                className="h-6 w-6  placeholder:text-black"
                checked={selectAll}
                onChange={handleCheckbox}
              />
              <span
                htmlFor="default-checkbox"
                className="font-normal ml-2 text-sm"
              >
                Seleziona tutti
              </span>
              <div className="flex items-center ">
                (<b>{formatBytes(bytes)}</b>)
              </div>
            </div>
            {fileSelected.length > 0 && (
              <div className="flex-1 flex gap-1 justify-end items-center h-[42px] text-lg mr-2">
                Selezionati: <b>{formatBytes(selectedBytes)}</b>
              </div>
            )}
          </div>
          <div className="flex gap-4 justify-end">
            {operazione === "edit" && (
              <>
                {fileSelected?.length > 0 && (
                  <div
                    className="icon-container-blue group"
                    onClick={() => {
                      if (selectedBytes <= 26214400) {
                        setOpenModalSelect(true);
                      } else {
                        toast.warning("25 MB superati!");
                      }
                    }}
                  >
                    <HiOutlineMail className="icon-blue" />
                  </div>
                )}
                <div
                  className="icon-container-blue group"
                  onClick={() => {
                    window.open(folder?.link, "_blank");
                  }}
                >
                  <HiMiniArrowTopRightOnSquare className="icon-blue" />
                </div>
                {/* <div
                  className="icon-container-red group"
                  onClick={handleDelete}
                >
                  <HiOutlineTrash className="icon-red" />
                </div> */}
              </>
            )}
            {!doc && (
              <>
                {saving ? (
                  <div
                    className="icon-container-green group "
                    onClick={() => toast.warning("Salvataggio in corso!")}
                  >
                    <AiOutlineLoading3Quarters className="icon-green animate-spin" />
                  </div>
                ) : (
                  <div
                    className="icon-container-green group "
                    onClick={handleSave}
                  >
                    <HiOutlineCheck className="icon-green" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {doc && (
          <>
            <div className="col-span-6 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-2 overflow-y-auto max-h-[30vh] 2xl:max-h-[50vh]">
              {/* <div className="col-span-3 sm:col-span-4 md:col-span-6 h-1 bg-sinistri-lightgreen rounded-full"></div> */}
              {/* <div className="col-span-3 sm:col-span-4 md:col-span-6 flex flex-wrap md:flex-nowrap items-end">
                <div className="w-full md:w-fit">
                  <Input
                    label={"Nome File"}
                    type="text"
                    onChange={handleSearchChange}
                    value={search}
                    icon={<HiOutlineSearch className="text-2xl" />}
                  />
                </div>
                <div className="flex items-center h-[42px] px-3 relative text-lg">
                  <input
                    type="checkbox"
                    className="h-6 w-6  placeholder:text-black"
                    checked={selectAll}
                    onChange={handleCheckbox}
                  />
                  <span
                    htmlFor="default-checkbox"
                    className="font-normal ml-2 text-lg"
                  >
                    Seleziona tutti
                  </span>
                  <div className="flex items-center ">
                    (<b>{formatBytes(bytes)}</b>)
                  </div>
                </div>
                
              </div> */}
              <FileUpload folderId={folder?.id} getFiles={getFiles} />

              {loadingFiles ? (
                <>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                  <div className="col-span-1">
                    <Skeleton variant="rounded" width="100%" height={192} />
                  </div>
                </>
              ) : (
                <>
                  {files?.length > 0 ? (
                    <>
                      {files?.map((file, index) => (
                        <ClickAway
                          key={index}
                          file={file}
                          setOpenModal={handleOpenModal}
                          getFiles={getFiles}
                          handleAddSelect={handleAddSelect}
                          handleRemoveSelect={handleRemoveSelect}
                          fileSelected={fileSelected}
                        />
                      ))}
                    </>
                  ) : (
                    <div className="col-span-3 sm:col-span-4 md:col-span-6 font-semibold">
                      Nessun file presente nella cartella
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        <div className="col-span-6 space-y-4">
          <div className="col-span-2 text-2xl text-center">Rinomina File</div>
          <Input
            label={"Nome File"}
            type="text"
            name="name"
            onChange={(e) => {
              setFile({ ...file, name: e.target.value });
            }}
            value={file?.name}
            icon={<HiOutlineFolder className="text-2xl" />}
          />
          <div className="flex gap-4 justify-end flex-1">
            <div className="icon-container-green group " onClick={handleRename}>
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </Modal>
      <ModalXL
        open={openModalSelect}
        handleClose={() => {
          setOpenModalSelect(false);
          resetType();
        }}
      >
        {emailType ? (
          <>
            <EmailForm
              sinistro={sinistro}
              testo={testoMail}
              type={emailType}
              files={fileSelected}
              resetType={resetType}
              setTab={setTab}
            />
          </>
        ) : (
          <div className={`col-span-2 duration-300 ${openSelect && "h-96"}`}>
            <span className=" text-lg font-normal">Tipologia Mail</span>
            <div
              className="w-full flex space-x-4 items-center p-4 bg-white border-gray-400 hover:border-black border rounded-md cursor-pointer relative"
              onClick={() => setOpenSelect(!openSelect)}
            >
              <div className="w-full flex-1">
                {!emailType ? "Seleziona Tipologia Email" : emailType}
              </div>
              <HiOutlineChevronDown
                className={`ablsolute right-0 text-2xl duration-300 ${
                  openSelect && "-rotate-180"
                }`}
              />
              <div
                className={`py-4 absolute top-[58px] -left-4 flex flex-col gap-2 bg-gray-100 border overflow-y-auto w-full rounded-md shadow-md ${
                  openSelect ? "opacity-100 z-50" : "opacity-0 z-0 hidden"
                }`}
              >
                <div class="relative flex items-center justify-center w-full">
                  <hr class="w-full border-t border-gray-300" />
                  <span class="absolute px-2 bg-gray-100 text-gray-600">
                    ATTI
                  </span>
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Atto Da Firmare")}
                >
                  Atto Da Firmare
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Atto Firmato")}
                >
                  Atto Firmato
                </div>
                <div class="relative flex items-center justify-center w-full">
                  <hr class="w-full border-t border-gray-300" />
                  <span class="absolute px-2 bg-gray-100 text-gray-600">
                    DOCUMENTAZIONE
                  </span>
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Invio Documentazione")}
                >
                  Invio Documentazione
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalXL>
    </>
  );
};
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const FileUpload = ({ folderId, getFiles }) => {
  const [files, setFiles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const {
    acceptedFiles,
    isDragAccept,
    isDragReject,
    isFocused,
    getRootProps,
    getInputProps,
  } = useDropzone();

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("folderId", folderId);

    try {
      const response = await api.post("documentazioni/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      getFiles();
      setOpenModal(false);
      setFiles([]);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  return (
    <>
      {/* <div className="relative col-span-1 h-48 border-dashed border-4 border-sinistri-lightgreen text-sinistri-lightgreen rounded-md flex flex-col justify-between px-2"> */}
      <div
        className="relative col-span-6 h-12 border-dashed border-4 border-sinistri-lightgreen text-sinistri-lightgreen rounded-md flex justify-center items-center px-2 cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        <div></div>
        <div>
          <HiOutlineCloudUpload className="icon-documentazioni-new" />
        </div>
        <div className="text-center">Carica Files</div>
      </div>
      <Modal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          setFiles([]);
        }}
      >
        <div className="col-span-3 sm:col-span-4 md:col-span-6 space-y-4">
          <div className="col-span-2 text-2xl text-center">Carica Files</div>
          <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Trascina e rilascia qui o fai clic per selezionare i file</p>
              </div>
            )}
          </Dropzone>
          <aside>
            <h4 style={{ marginTop: "10px", textAlign: "center" }}>Files</h4>
            {files?.map((file) => {
              return (
                <>
                  <ul style={{ textAlign: "center", padding: 0 }}>
                    {file.path}
                  </ul>
                </>
              );
            })}
          </aside>
          {/* <input type="file" multiple onChange={handleFileChange} /> */}
          <div className="flex gap-4 justify-end flex-1">
            <div className="icon-container-green group " onClick={handleUpload}>
              <HiOutlineUpload className="icon-green" />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const EmailForm = ({ type, sinistro, testo, files, resetType, setTab }) => {
  const [emailsAgenzia, setEmailsAgenzia] = useState([]);
  const [emailPrincipaleAgenzia, setEmailPrincipaleAgenzia] = useState([]);
  const [amministratore, setAmministratore] = useState([]);
  const [multiDestinatari, setMultiDestinatari] = useState({
    perito: 0,
    agenzia: 0,
    danneggiati: 0,
  });

  const [sending, setSending] = useState(false);
  const [mail, setMail] = useState({
    mittente: "",
    alias: "",
    password: "",
    destinatari: "",
    copia: "",
    copiaNascosta: "",
    oggetto: "",
    testo: "",
    firma: "",
  });

  const [openPopUpContatti, setOpenPopUpContatti] = useState(false);
  const [openPopUpInfo, setOpenPopUpInfo] = useState(false);
  const [franchigeSinistro, setFranchigeSinistro] = useState([]);
  const [impreseSinistro, setImpreseSinistro] = useState([]);
  const [personeInteressateSinistro, setPersoneInteressateSinistro] = useState(
    []
  );
  const [peritiSinistro, setPeritiSinistro] = useState([]);
  const [studioSinistro, setStudioSinistro] = useState(null);
  const [emailAgenziaSinistro, setEmailAgenziaSinistro] = useState([]);
  const [agenziaSinistro, setAgenziaSinistro] = useState(null);

  const handleChangeMail = (e) => {
    setMail({ ...mail, [e.target.name]: e.target.value });
  };

  const formatNumber = (num) => {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "˙");
  };

  const copyValue = (val) => {
    navigator.clipboard.writeText(val);
    toast.success("Valore copiato!");
  };

  const handlePopUpInfo = async () => {
    setOpenPopUpInfo(true);
    let responseImpresa = await api.get("imprese/sinistro/" + sinistro.id);
    setImpreseSinistro(responseImpresa.data.data);
    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + sinistro.id
    );
    setPersoneInteressateSinistro(responseRelPersone.data.data);
    let responseGaranzie = await api.get(
      "polizze/franchigie/" + sinistro?.id_polizza
    );
    setFranchigeSinistro(responseGaranzie.data.data);
  };

  const handlePopUpContatti = async () => {
    setOpenPopUpContatti(true);
    let responseImpresa = await api.get("imprese/sinistro/" + sinistro.id);
    setImpreseSinistro(responseImpresa.data.data);
    console.log("Impresa");
    console.log(responseImpresa.data.data);
    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + sinistro.id
    );
    setPersoneInteressateSinistro(responseRelPersone.data.data);
    console.log("Persone");
    console.log(responseRelPersone.data.data);
    if (sinistro?.id_studio_peritale) {
      let responseRelStudi = await api.get(
        "studi_peritali/" + sinistro.id_studio_peritale
      );
      setStudioSinistro(responseRelStudi.data.data[0]);
      console.log("Studio");
      console.log(responseRelStudi.data.data);

      let responseRelPeriti = await api.get(
        "studi_peritali/periti/" + sinistro.id_studio_peritale
      );
      setPeritiSinistro(responseRelPeriti.data.data);
      console.log("Perito");
      console.log(responseRelPeriti.data.data);
    }
    if (sinistro?.id_agenzia) {
      let responseAgenzia = await api.get("agenzie");
      const ag = responseAgenzia.data.data.filter(
        (el) => el.id === sinistro?.id_agenzia
      )[0];
      setAgenziaSinistro(ag);
      let responseEmailAgenzie = await api.get(
        "agenzie/email/" + sinistro.id_agenzia
      );
      setEmailAgenziaSinistro(responseEmailAgenzie.data.data);
      console.log("Agenzia");
      console.log(responseEmailAgenzie.data.data);
    }
  };

  const handleSendEmail = async () => {
    if (mail.destinatari) {
      try {
        setSending(true);
        await api.post("email/", {
          mittente: mail.mittente,
          alias: mail.alias,
          password: mail.password,
          destinatari: mail.destinatari,
          copia: mail?.copia,
          copiaNascosta: mail?.copiaNascosta,
          oggetto: mail.oggetto,
          allegati: files.map((el) => el.id),
          testo: mail.testo,
          firma: mail.firma,
          utente: sessionStorage.getItem("Username"),
          sinistro: sinistro.id,
          tipologia: type,
        });
        toast.success("Email inviata!");
        resetType();
        if (type !== "Invio Documentazione") {
          setTimeout(() => {
            setTab(1);
          }, 1000);
        }
      } catch (error) {
        toast.error("Errore durante l'invio!");
      }
      setSending(false);
    } else {
      toast.error("Campo Destinatari obbligatoro!");
    }
  };

  useEffect(() => {
    (async () => {
      let responseAgenzia = await api.get("agenzie");
      const ag = responseAgenzia.data.data.filter(
        (el) => el.id === sinistro?.id_agenzia
      )[0];
      setEmailsAgenzia(ag?.emails);
      setEmailPrincipaleAgenzia(ag?.email);
      let responseAmministratori = await api.get("amministratori");
      let amm = responseAmministratori.data.data?.filter(
        (el) => el.id === sinistro.id_amministratore
      )[0];
      setAmministratore(amm);
      let responseEmailAmministratori = await api.get(
        "amministratori/referente/" + amm?.id
      );
      let responseRelPersone = await api.get(
        "persone_interessate/sinistro/" + sinistro?.id
      );
      let maxThreePersone = responseRelPersone.data.data;
      if (responseRelPersone.data.data?.length >= 3) {
        maxThreePersone = responseRelPersone.data.data?.slice(0, 3);
      }
      let responseUtenti = await api.get(
        "utenti/" + sessionStorage.getItem("Username")
      );

      let persOggetto = maxThreePersone?.map((pers) => " " + pers?.cognome);
      persOggetto = persOggetto?.join("");

      //OGGETTO MAIL
      let riferimento = sinistro?.riferimento;
      let data = sinistro?.data
        ? moment(sinistro?.data).format("YY") + " "
        : "";
      let condominio =
        sinistro?.condominio !== "" ? sinistro?.condominio + " " : " ";
        let danno = sinistro?.danno ? sinistro?.danno : " ";
      let ol_scheda =
        sinistro?.ol_scheda !== ""
          ? "scheda " + sinistro?.ol_scheda + " "
          : " ";
      let n_sinistro = sinistro?.n_sinistro ? sinistro?.n_sinistro + " " : " ";
      let rif_perito = sinistro?.rif_perito ? sinistro?.rif_perito + " " : " ";

      let oggetto =
        riferimento +
        "/" +
        data +
        condominio +
        ol_scheda +
        n_sinistro +
        persOggetto +
        " " +
        danno +
        rif_perito;
      let destinatari = "";
      let copia = "";
      let testo_aggintivo = "";

      switch (type) {
        case "Atto Da Firmare":
          //OFFERTA
          let responseLiquidazioni = await api.get("offerte/" + sinistro?.id);
          //AMMINISTRATORI
          destinatari = responseEmailAmministratori.data.data
            ?.filter((responsabili) => responsabili.principale === 1)
            .map((res) => res.email);
          let responseFranchigie = await api.get(
            "polizze/franchigie/sinistro/" + sinistro?.id
          );
          //FRANCHIGIA
          if (responseFranchigie.length > 0) {
            let franchigia = responseFranchigie.data.data.filter(
              (el) => el.id_garanzia === sinistro?.id_tipologia_sinistro
            );
            testo_aggintivo = `\n\nFranchigia: ${
              franchigia[0]?.id_garanzia === 1 ? "importo RG" : "importo "
            } ${franchigia[0]?.valore ? franchigia[0]?.valore : ""} ${
              franchigia[0]?.doppio_valore
                ? "importo AC " + franchigia[0]?.doppio_valore
                : ""
            } ${
              franchigia[0]?.minimo ? "minimo " + franchigia[0]?.minimo : ""
            } ${
              franchigia[0]?.percentuale
                ? "percentuale " + franchigia[0]?.percentuale + "%"
                : ""
            }`;
          }
          //PERSONE
          let responseRelPersone = await api.get(
            "persone_interessate/sinistro/" + sinistro?.id
          );

          const sommaTotale = responseRelPersone.data.data?.reduce(
            (sum, item) => sum + item.liquidazione,
            0
          );
          if (responseRelPersone.data.data?.length > 0) {
            testo_aggintivo =
              testo_aggintivo +
              `\n\nTotale liquidazioni Persone Interessate: ${sommaTotale}${
                responseRelPersone.data.data?.length > 0 && "€"
              }`;
          }
          responseRelPersone.data.data
            .filter((el) => el?.liquidazione !== 0)
            ?.map((persona) => {
              testo_aggintivo =
                testo_aggintivo +
                `\n-${persona?.cognome} ${persona?.nome} importo liquidato: ${
                  persona?.liquidazione ? persona.liquidazione + "€" : ""
                }`;
            });
          //IMPORTO RESTANTE
          const ultimaOfferata = responseLiquidazioni.data.data[0];
          let restante = ultimaOfferata?.offerta - sommaTotale;
          testo_aggintivo =
            testo_aggintivo + `\n${restante && "-Rimanente " + restante + "€"}`;
          testo_aggintivo =
            testo_aggintivo + `\n${restante && "-Rimanente " + restante + "€"}`;
          //IMPRESE
          let responseImpresa = await api.get(
            "imprese/sinistro/" + sinistro.id
          );
          let filterFatture = responseImpresa.data.data?.filter(
            (fatt) => fatt.fattura
          );
          if (filterFatture?.length > 0) {
            testo_aggintivo =
              testo_aggintivo +
              `\n\nTotale costi Imprese: ${filterFatture?.reduce(
                (sum, item) => sum + item.fattura,
                0
              )}${filterFatture?.length > 0 && "€"}`;
            filterFatture?.map((impresa) => {
              testo_aggintivo =
                testo_aggintivo +
                `\n-${impresa?.impresa} -${
                  impresa?.n_fattura ? " n°" + impresa?.n_fattura + " -" : ""
                } fattura: ${impresa?.fattura ? impresa?.fattura + "€" : ""}`;
            });
          }
          break;
        case "Atto Firmato":
          let responsePeriti = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          if (
            sinistro?.id_perito &&
            responsePeriti.data.data?.filter(
              (p) => sinistro.id_perito === p.id
            )[0]?.email
          ) {
            destinatari = responsePeriti.data.data?.filter(
              (p) => sinistro.id_perito === p.id
            )[0]?.email;
          } else {
            toast.warning("Nessun indirizzo email del perito!");
            let responseStudiPeritali = await api.get(
              "studi_peritali/" + sinistro?.id_studio_peritale
            );
            if (
              sinistro?.id_studio_peritale &&
              responseStudiPeritali.data.data?.length > 0
            ) {
              destinatari = responseStudiPeritali.data.data[0]?.email
                ? responseStudiPeritali.data.data[0]?.email + ","
                : "";
            } else {
              toast.warning("Nessun indirizzo email dello studio peritale!");
            }
          }
          /* let responsePeriti = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          responsePeriti.data.data.length === 0 &&
            toast.warning("Nessun indirizzo email presente!");

          const emailPerito = responsePeriti.data.data?.filter(
            (p) => sinistro.id_perito === p.id
          )[0]?.email;
          if (emailPerito) {
            destinatari = emailPerito + ",";
          } else {
            destinatari = responsePeriti.data.data[0]?.email_studio
              ? responsePeriti.data.data[0]?.email_studio + ","
              : "";
          } */
          break;
        case "Invio Documentazione":
          let responsePeritiDoc = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          if (
            sinistro?.id_perito &&
            responsePeritiDoc.data.data?.filter(
              (p) => sinistro.id_perito === p.id
            )[0]?.email
          ) {
            destinatari = responsePeritiDoc.data.data?.filter(
              (p) => sinistro.id_perito === p.id
            )[0]?.email;
          } else {
            toast.warning("Nessun indirizzo email del perito!");
            let responseStudiPeritali = await api.get(
              "studi_peritali/" + sinistro?.id_studio_peritale
            );
            if (
              sinistro?.id_studio_peritale &&
              responseStudiPeritali.data.data?.length > 0
            ) {
              destinatari = responseStudiPeritali.data.data[0]?.email
                ? responseStudiPeritali.data.data[0]?.email + ","
                : "";
            } else {
              toast.warning("Nessun indirizzo email dello studio peritale!");
            }
          }
          /* let responsePeritiDoc = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          //CONTROLLO IL PERITO DI RIFERIMENTO
          const emailPeritoDoc = responsePeritiDoc?.data.data.filter(
            (p) => sinistro.id_perito === p.id
          )[0]?.email;
          if (emailPeritoDoc) {
            destinatari = emailPeritoDoc;
          } else {
            let responseStudi = await api.get("studi_peritali");
            destinatari = responseStudi.data.data?.filter(
              (p) => sinistro.id_studio_peritale === p.id
            )[0]?.email;
          }
          if (!destinatari) {
            toast.warning("Nessun indirizzo email del perito presente!");
          } */
          break;
        default:
          break;
      }
      setMail({
        ...mail,
        mittente: amm?.email ? amm.email : "",
        alias: amm?.alias_check === 1 ? amm?.alias : "",
        password: amm?.password_email ? amm.password_email : "",
        destinatari: destinatari,
        copia: copia,
        copiaNascosta: responseUtenti.data?.data[0]?.copia_nascosta,
        firma: amm.firma ? amm.firma : "",
        oggetto: oggetto,
        testo: testo + " " + testo_aggintivo,
      });
    })();
  }, []);

  return (
    <div className="grid grid-cols-2 gap-2 h-full px-2">
      <div className="flex col-span-2 justify-between">
        <div className=""></div>
        <div className=" text-2xl text-center">{type}</div>
        <div className="flex gap-2">
          <div
            className="icon-container-green group"
            onClick={handlePopUpContatti}
          >
            <HiOutlineBookOpen className="icon-green" />
          </div>
          <div className="icon-container-green group" onClick={handlePopUpInfo}>
            <HiOutlineInformationCircle className="icon-green" />
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <Input
          label={"Mittente"}
          type="text"
          name="mittente"
          onChange={handleChangeMail}
          value={mail?.mittente}
          icon={<HiOutlineUser className="text-2xl" />}
        />
      </div>
      {mail?.alias && (
        <div className="col-span-2">
          <Input
            label={"Alias"}
            type="text"
            name="alias"
            onChange={handleChangeMail}
            value={mail?.alias}
            icon={<HiOutlineUser className="text-2xl" />}
          />
        </div>
      )}
      {type === "Pratica" && (
        <>
          <div className="col-span-2 flex gap-3">
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="perito"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.perito === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    perito: multiDestinatari?.perito === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Perito
              </span>
            </div>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="agenzia"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.agenzia === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    agenzia: multiDestinatari?.agenzia === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Agenzia
              </span>
            </div>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="danneggiati"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.danneggiati === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    danneggiati: multiDestinatari?.danneggiati === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Danneggiati
              </span>
            </div>
          </div>
        </>
      )}
      <div className="col-span-2">
        <Input
          label={"Destinatari"}
          type="text"
          name="destinatari"
          onChange={handleChangeMail}
          value={mail?.destinatari}
          icon={<HiOutlineUser className="text-2xl" />}
        />
      </div>
      <div className="col-span-2">
        <Input
          label={"In Copia"}
          type="text"
          name="copia"
          onChange={handleChangeMail}
          value={mail?.copia}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div>
      <div className="col-span-2">
        <Input
          label={"In Copia Nascosta"}
          type="text"
          name="copiaNascosta"
          onChange={handleChangeMail}
          value={mail?.copiaNascosta}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div>
      <div className="col-span-2">
        <Input
          label={"Oggetto"}
          type="text"
          name="oggetto"
          onChange={handleChangeMail}
          value={mail?.oggetto}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div>
      <div className="col-span-2 ">
        <span className="text-lg font-normal">Allegati:</span>
        <div className="relative">
          <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
            <HiOutlinePaperClip className="text-2xl" />
          </span>
          <div className="input flex items-center flex-wrap">
            {files?.map((file) => {
              return (
                <>
                  <div
                    className="underline"
                    onClick={() => window.open(file?.webViewLink, "_blank")}
                  >
                    {file.name}
                  </div>
                  ;
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="col-span-2 ">
        <span className="text-lg font-normal">Testo</span>
        <div className="relative">
          <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
            <HiOutlineDocumentText className="text-2xl" />
          </span>

          <textarea
            rows={5}
            type="text"
            name="testo"
            className="input flex items-center"
            placeholder="Inserisci Testo"
            onChange={handleChangeMail}
            value={mail?.testo}
          />
        </div>
      </div>
      {/* <div className="col-span-2 ">
        <span className="text-lg font-normal">Firma</span>
        <div className="relative">
          <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
            <PiSignature className="text-2xl" />
          </span>

          <textarea
            rows={5}
            type="text"
            name="testo"
            className="input flex items-center"
            //placeholder="Inserisci Testo"
            //onChange={handleChangeMail}
            value={mail?.firma}
          />
        </div>
      </div> */}
      <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
        <>
          {sending ? (
            <div
              className="icon-container-green group "
              onClick={() => toast.warning("Invio in corso!")}
            >
              <AiOutlineLoading3Quarters className="icon-green animate-spin" />
            </div>
          ) : (
            <div
              className="icon-container-green h-min group "
              onClick={handleSendEmail}
            >
              <HiOutlinePaperAirplane className="icon-green" />
            </div>
          )}
        </>
      </div>
      <Modal
        open={openPopUpContatti}
        handleClose={async () => {
          setOpenPopUpContatti(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Contatti</h2>
        <div className="w-full flex flex-col">
          {personeInteressateSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Danneggiati:</div>
              {personeInteressateSinistro.map((pers) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {pers?.cognome} {pers?.nome}: <b>{pers?.email}</b>
                  {pers?.email && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() => copyValue(pers.email)}
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {agenziaSinistro && (
            <div className="w-full flex flex-col">
              <div className="text-lg font-semibold">Agenzia:</div>
              <div className="px-2 py-1 flex items-center gap-2">
                {agenziaSinistro?.nome}: <b>{agenziaSinistro?.email}</b>
                {agenziaSinistro?.email && (
                  <div
                    className="icon-container-green-min group"
                    onClick={() => copyValue(agenziaSinistro?.email)}
                  >
                    <HiOutlineClipboardCopy className="icon-green-min" />
                  </div>
                )}
              </div>
            </div>
          )}
          {emailAgenziaSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Email Agenzia:</div>
              {emailAgenziaSinistro.map((email) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  <b>{email?.email}</b>
                  {email?.email && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() => copyValue(email.email)}
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {studioSinistro && (
            <div className="w-full flex flex-col">
              <div className="text-lg font-semibold">Studio Peritale:</div>
              <div className="px-2 py-1 flex items-center gap-2">
                {studioSinistro?.nome}: <b>{studioSinistro?.email}</b>
                {studioSinistro?.email && (
                  <div
                    className="icon-container-green-min group"
                    onClick={() => copyValue(studioSinistro?.email)}
                  >
                    <HiOutlineClipboardCopy className="icon-green-min" />
                  </div>
                )}
              </div>
            </div>
          )}
          {peritiSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Periti:</div>
              {peritiSinistro.map((perito) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {perito?.cognome} {perito?.nome}: <b>{perito?.email}</b>
                  {perito?.email && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() => copyValue(perito.email)}
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {impreseSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Imprese:</div>
              {impreseSinistro.map((impresa) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {impresa?.impresa}: <b>{impresa?.email}</b>
                  {impresa?.email && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() => copyValue(impresa.email)}
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </Modal>
      <Modal
        open={openPopUpInfo}
        handleClose={async () => {
          setOpenPopUpInfo(false);
        }}
      >
        <h2 className="text-2xl text-center mb-2">Informazioni</h2>
        <div className="w-full flex flex-col">
          {personeInteressateSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Danneggiati:</div>
              {personeInteressateSinistro.map((pers) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {pers?.cognome} {pers?.nome}: <b>{pers?.telefono}</b>
                  {pers?.telefono && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() =>
                        copyValue(
                          pers?.cognome +
                            " " +
                            pers?.nome +
                            ": " +
                            pers.telefono
                        )
                      }
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {impreseSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Imprese:</div>
              {impreseSinistro.map((impresa) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {impresa?.impresa} - n° fattura: <b>{impresa?.n_fattura}</b>
                  {impresa?.n_fattura && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() =>
                        copyValue(
                          impresa?.impresa +
                            " - n° fattura: " +
                            impresa?.n_fattura
                        )
                      }
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {sinistro?.condominio && (
            <>
              <div className="text-lg font-semibold">Condominio:</div>
              <div className="px-2 py-1 flex items-center gap-2">
                {sinistro.condominio} - n° polizza: <b>{sinistro?.n_polizza}</b>
                {sinistro?.n_polizza && (
                  <div
                    className="icon-container-green-min group"
                    onClick={() =>
                      copyValue(
                        sinistro.condominio +
                          " - n° polizza: " +
                          sinistro?.n_polizza
                      )
                    }
                  >
                    <HiOutlineClipboardCopy className="icon-green-min" />
                  </div>
                )}
              </div>
              {sinistro?.valore_assicurato && (
                <>
                  <div className="px-2 flex items-center gap-2">
                    Valore assicurato:{" "}
                    <b>{formatNumber(sinistro.valore_assicurato)}€</b>
                    <div
                      className="icon-container-green-min group"
                      onClick={() =>
                        copyValue(
                          "Valore assicurato: " +
                            formatNumber(sinistro.valore_assicurato) +
                            "€"
                        )
                      }
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  </div>
                </>
              )}
              {franchigeSinistro?.length > 0 && (
                <>
                  <div className="text-lg font-semibold">Franchigie:</div>
                  {franchigeSinistro.map((franchigia) => {
                    let frase = franchigia?.garanzia + ": ";
                    frase += franchigia?.valore
                      ? " valore " + franchigia?.valore
                      : "";
                    frase += franchigia?.doppio_valore
                      ? " valore AC " + franchigia.doppio_valore
                      : "";
                    frase +=
                      franchigia?.franchigia_scoperto === 1 &&
                      franchigia?.percentuale
                        ? " percentuale " + franchigia.percentuale + "%"
                        : "";
                    frase +=
                      franchigia?.franchigia_scoperto === 1 &&
                      franchigia?.minimo
                        ? " minimo " + franchigia.minimo
                        : "";

                    return (
                      <div className="px-2 py-1 flex items-center gap-2">
                        {frase}
                        <div
                          className="icon-container-green-min group"
                          onClick={() => copyValue(frase)}
                        >
                          <HiOutlineClipboardCopy className="icon-green-min" />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default function ClickAway({
  file,
  setOpenModal,
  getFiles,
  fileSelected,
  handleAddSelect,
  handleRemoveSelect,
}) {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  function formatBytes(bytes) {
    if (typeof bytes !== "number" || bytes < 0) {
      throw new Error("Input must be a non-negative number");
    }
    const units = ["bytes", "KB", "MB"];
    let unitIndex = 0;

    while (bytes >= 1024 && unitIndex < units.length - 1) {
      bytes /= 1024;
      unitIndex++;
    }

    return `${bytes.toFixed(2)} ${units[unitIndex]}`;
  }

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  const handleDeleteFile = async () => {
    try {
      await api.delete("documentazioni/files/" + file?.id);
      toast.success("File eliminato con successo!");
      setOpenDelete(false);
      setOpen(false);
      getFiles();
    } catch (error) {
      toast.error("errore durante l'eliminazione!");
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div
          key={file?.id}
          /* className="relative col-span-1 h-48 bg-sinistri-lightgreen text-white rounded-md flex flex-col justify-between px-2" */
          className="relative col-span-6 lg:col-span-3 h-12 bg-sinistri-lightgreen text-white rounded-md grid grid-cols-8 justify-start items-center px-2 "
        >
          <div className="col-span-1 flex">
            <HiMiniEllipsisVertical
              className=" text-2xl cursor-pointer hover:scale-125 transition-all"
              onClick={handleClick}
            />
            {fileSelected?.filter((f) => f.id === file.id)?.length !== 0 ? (
              <>
                <MdOutlineCheckBox
                  className=" text-2xl cursor-pointer hover:scale-125 transition-all"
                  onClick={() => {
                    if (
                      file?.mimeType !== "application/vnd.google-apps.folder"
                    ) {
                      handleRemoveSelect(file);
                    } else {
                      toast.warning("Non è possibile selezionare le cartelle!");
                    }
                  }}
                />
              </>
            ) : (
              <>
                <MdOutlineCheckBoxOutlineBlank
                  className=" text-2xl cursor-pointer hover:scale-125 transition-all"
                  onClick={() => {
                    if (
                      file?.mimeType !== "application/vnd.google-apps.folder"
                    ) {
                      handleAddSelect(file);
                    } else {
                      toast.warning("Non è possibile selezionare le cartelle!");
                    }
                  }}
                />
              </>
            )}
          </div>
          {open ? (
            <div
              className={`px-2 absolute top-10 -left-0 flex flex-col gap-2  bg-gray-200 border w-full rounded-md shadow-md z-50`}
            >
              <div
                className="flex gap-2 items-center text-black  cursor-pointer hover:bg-gray-100 py-2 rounded-md"
                onClick={() => setOpenModal(file)}
              >
                <HiOutlinePencil className="text-xl  " />
                Rinomina
              </div>
              <div
                className="flex gap-2 items-center text-black cursor-pointer hover:bg-gray-100 py-2 rounded-md"
                onClick={() => setOpenDelete(true)}
              >
                <HiOutlineTrash className="text-xl " />
                Elimina
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className="col-span-7 flex justify-start items-center"
            onClick={() => {
              window.open(file?.webViewLink, "_blank");
            }}
          >
            {getMime(file?.mimeType)}
            <Tooltip title={<div className="text-sm">{file?.name}</div>}>
              <div className="truncate self-center cursor-pointer flex items-center">
                <span className="text-sm pr-1">
                  ({formatBytes(Number(file?.size))})
                </span>
                {file?.name}
              </div>
            </Tooltip>
          </div>
        </div>
      </ClickAwayListener>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={file?.name}
        handleDelete={handleDeleteFile}
      />
    </>
  );
}
