import { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { Skeleton } from "@mui/material";
import {
  HiOutlineAtSymbol,
  HiOutlineBuildingOffice,
  HiOutlineCheck,
  HiOutlineClipboard,
  HiOutlineIdentification,
  HiOutlinePhone,
  HiOutlinePlus,
  HiOutlineSquare3Stack3D,
  HiOutlineTrash,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import { Amministratore } from "../utility/useAmministraotre";
import { Autocomplete } from "../components/Autocomplete";
import api from "../components/api/api";
import { toast } from "react-toastify";
import { ModalDelete } from "../components/ModalDelete";
import { ModalDuplicate } from "../components/ModalDuplicate";

const columns = [
  {
    field: "cognome",
    headerName: "Cognome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "inquilino",
    headerName: "Inquilino",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "riferimento",
    headerName: "Note/Riferimento",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "condominio",
    headerName: "Condominio",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

export const PersoneInteressate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [personeInteressate, setPersoneInteressata] = useState([]);
  const [condominii, setCondominii] = useState([]);

  const [amministratore, setAmministratore] = useContext(Amministratore);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() =>
                navigate("/Nuova-Persona-Interessata", {
                  state: { condominii: condominii },
                })
              }
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let responsePersone;
      let responseCondominii;
      if (amministratore !== 0) {
        responsePersone = await api.get(
          "persone_interessate/amministratore/" + amministratore
        );
        responseCondominii = await api.get("condominii/" + amministratore);
      } else {
        responseCondominii = await api.get("condominii");
        responsePersone = await api.get("persone_interessate");
      }
      setPersoneInteressata(responsePersone.data.data);
      setCondominii(responseCondominii.data.data);
      setLoading(false);
    })();
  }, [amministratore]);

  return (
    <div className="page-container ">
      <div className="w-full container-box h-full">
        <div className=""></div>
        {!loading ? (
          <DataGridPro
            ignoreDiacritics
            rows={personeInteressate}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: CustomToolbar }}
            onRowClick={(row) => {
              navigate("/Dettaglio-Persona-Interessata", {
                state: { personaInteressata: row.row, condominii: condominii },
              });
            }}
            disableRowSelectionOnClick
          />
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
            <div className="flex w-full gap-2">
              <Skeleton variant="rounded" width={70} height={70} />
              <Skeleton variant="rounded" width="90%" height={70} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const NuovaPersonaInteressata = ({
  external,
  idCondominio,
  condominiiList,
}) => {
  const location = useLocation();
  const [condominii, setCondominii] = useState([]);
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [personaInteressata, setPersonaInteressata] = useState({
    id_sinistro: "",
    id_amministratore: "",
    id_condominio: "",
    nome: "",
    cognome: "",
    telefono: "",
    email: "",
    piano: "",
    riferimento: "",
    inquilino: "",
  });

  const [openDuplicate, setOpenDuplicate] = useState(false);

  const handleChange = (e) => {
    setPersonaInteressata({
      ...personaInteressata,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeUppercase = (e) => {
    setPersonaInteressata({
      ...personaInteressata,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const duplicateCheck = async () => {
    if (!personaInteressata?.id_condominio) {
      toast.error("Condominio obbligatorio!");
    } else {
      let responsePersone;
      if (amministratore !== 0) {
        responsePersone = await api.get(
          "persone_interessate/condominio/" + personaInteressata?.id_condominio
        );
      } else {
        responsePersone = await api.get("persone_interessate");
      }
      if (
        responsePersone.data.data?.every(
          (pers) =>
            pers?.nome + pers?.cognome !==
            personaInteressata?.nome + personaInteressata?.cognome
        )
      ) {
        handleSave();
      } else {
        setOpenDuplicate(true);
      }
    }
  };

  const handleSave = async () => {
    /* if (personaInteressata.nome === "") {
      toast.error("Campo Nome obbligatorio!");
    } else */ if (personaInteressata.cognome === "") {
      toast.error("Campo Cognome obbligatorio!");
    } else {
      if (personaInteressata?.id_condominio !== "") {
        try {
          await api.post("persone_interessate", {
            persona_interessata: { ...personaInteressata },
          });
          toast.success("Salvataggio completato!");
        } catch (error) {
          toast.error("Errore durante il salvataggio. Riprova!");
        }
      } else {
        toast.error("Selezionare un condominio!");
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (location.state?.condominio || external) {
        setPersonaInteressata({
          ...personaInteressata,
          id_condominio: external ? idCondominio : location.state?.condominio,
        });
      }
      if (location.state?.condominii) {
        setCondominii(location.state?.condominii);
      } else {
        setCondominii(condominiiList);
      }
    })();
  }, []);

  return (
    <>
      <div className={!external && "page-container "}>
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={personaInteressata?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Cognome"}
              type="text"
              name="cognome"
              onChange={handleChangeUppercase}
              value={personaInteressata?.cognome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={personaInteressata?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Piano Condominio"}
              type="text"
              name="piano"
              onChange={handleChange}
              value={personaInteressata?.piano}
              icon={<HiOutlineSquare3Stack3D className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Autocomplete
              disabled={external}
              suggestions={condominii}
              fields={["nome"]}
              keyField="id"
              value={personaInteressata?.id_condominio}
              id="nome"
              label="Condominio"
              name="nome"
              onChangeFunc={(e) => {
                setPersonaInteressata({
                  ...personaInteressata,
                  id_condominio: e,
                });
              }}
              icon={<HiOutlineBuildingOffice className="text-2xl" />}
            />
          </div>
          {/* <div className="col-span-2 md:col-span-1 ">
            <Autocomplete
              suggestions={rowsAmministratori}
              fields={["cognome"]}
              keyField="id"
              value={personaInteressata?.id_amministratore}
              id="nome"
              label="Amministratore"
              name="nome"
              onChangeFunc={(e) => {
                setPersonaInteressata({
                  ...personaInteressata,
                  id_amministratore: e,
                });
              }}
              icon={<HiOutlineBuildingOffice className="text-2xl" />}
            />
          </div> */}
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={personaInteressata?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 ">
            <span className="text-lg font-normal">Inquilino</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                rows={1}
                type="text"
                name="inquilino"
                className="input flex items-center"
                placeholder="Inserisci Inquilino"
                onChange={handleChange}
                value={personaInteressata?.inquilino}
              />
            </div>
          </div>
          <div className="col-span-2 ">
            <span className="text-lg font-normal">Note/Riferimenti</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                rows={1}
                type="text"
                name="riferimento"
                className="input flex items-center"
                placeholder="Inserisci Riferimenti"
                onChange={handleChange}
                value={personaInteressata?.riferimento}
              />
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-green h-min group "
              onClick={duplicateCheck}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <ModalDuplicate
        open={openDuplicate}
        handleClose={() => setOpenDuplicate(false)}
        handleDuplicate={handleSave}
      />
    </>
  );
};

export const DettaglioPersonaInteressata = () => {
  const location = useLocation();
  const [amministratore, setAmministratore] = useContext(Amministratore);

  const [openDelete, setOpenDelete] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);

  const [personaInteressata, setPersonaInteressata] = useState(null);

  const handleChange = (e) => {
    setPersonaInteressata({
      ...personaInteressata,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeUppercase = (e) => {
    setPersonaInteressata({
      ...personaInteressata,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const duplicateCheck = async () => {
    if (!personaInteressata?.id_condominio) {
      toast.error("Condominio obbligatorio!");
    } else {
    let responsePersone;
    if (amministratore !== 0) {
      responsePersone = await api.get(
        "persone_interessate/condominio/" + personaInteressata?.id_condominio
      );
    } else {
      responsePersone = await api.get("persone_interessate");
    }
    if (
      responsePersone.data.data
        ?.filter((el) => el.id !== location.state?.personaInteressata?.id)
        .every(
          (pers) =>
            pers?.nome + pers?.cognome !==
            personaInteressata?.nome + personaInteressata?.cognome
        )
    ) {
      handleSave();
    } else {
      setOpenDuplicate(true);
    }}
  };

  const handleSave = async () => {
    try {
      await api.put("persone_interessate", {
        persona_interessata: personaInteressata,
      });
      toast.success("Modifica completata!");
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("persone_interessate/" + personaInteressata?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };

  useEffect(() => {
    (() => {
      setPersonaInteressata(location.state?.personaInteressata);
    })();
  }, []);
  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-4 px-2 ">
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Nome"}
              type="text"
              name="nome"
              onChange={handleChangeUppercase}
              value={personaInteressata?.nome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Cognome"}
              type="text"
              name="cognome"
              onChange={handleChangeUppercase}
              value={personaInteressata?.cognome}
              icon={<HiOutlineIdentification className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Telefono"}
              type="text"
              name="telefono"
              onChange={handleChange}
              value={personaInteressata?.telefono}
              icon={<HiOutlinePhone className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Piano Condominio"}
              type="text"
              name="piano"
              onChange={handleChange}
              value={personaInteressata?.piano}
              icon={<HiOutlineSquare3Stack3D className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Autocomplete
              suggestions={location.state?.condominii}
              fields={["nome"]}
              keyField="id"
              value={personaInteressata?.id_condominio}
              id="nome"
              label="Condominio"
              name="nome"
              onChangeFunc={(e) => {
                setPersonaInteressata({
                  ...personaInteressata,
                  id_condominio: e,
                });
              }}
              icon={<HiOutlineBuildingOffice className="text-2xl" />}
            />
          </div>
          {/* <div className="col-span-2 md:col-span-1 ">
            <Autocomplete
              suggestions={rowsAmministratori}
              fields={["cognome"]}
              keyField="id"
              value={personaInteressata?.id_amministratore}
              id="nome"
              label="Amministratore"
              name="nome"
              onChangeFunc={(e) => {
                setPersonaInteressata({
                  ...personaInteressata,
                  id_amministratore: e,
                });
              }}
              icon={<HiOutlineBuildingOffice className="text-2xl" />}
            />
          </div> */}
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Email"}
              type="text"
              name="email"
              onChange={handleChange}
              value={personaInteressata?.email}
              icon={<HiOutlineAtSymbol className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 ">
            <span className="text-lg font-normal">Inquilino</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                rows={1}
                type="text"
                name="inquilino"
                className="input flex items-center"
                placeholder="Inserisci Inquilino"
                onChange={handleChange}
                value={personaInteressata?.inquilino}
                maxLength={125}
              />
            </div>
          </div>
          <div className="col-span-2 ">
            <span className="text-lg font-normal">Note/Riferimenti</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                rows={1}
                type="text"
                name="riferimento"
                className="input flex items-center"
                placeholder="Inserisci Riferimenti"
                onChange={handleChange}
                value={personaInteressata?.riferimento}
                maxLength={125}
              />
            </div>
          </div>
          <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
            <div
              className="icon-container-green h-min group "
              onClick={duplicateCheck}
            >
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
        </div>
      </div>
      <ModalDuplicate
        open={openDuplicate}
        handleClose={() => setOpenDuplicate(false)}
        handleDuplicate={handleSave}
      />
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={personaInteressata?.cognome}
        handleDelete={handleDelete}
      />
    </>
  );
};
