import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import loginImg from "../assets/images/login-sinistri.svg";

import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { HiOutlineLockClosed, HiOutlineUser } from "react-icons/hi2";
import { Amministratore } from "../utility/useAmministraotre";
import api from "../components/api/api";

export const Login = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ user: "", password: "" });
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const navigate = useNavigate();

  const handleChange = (e) => {
    let personaleData = { ...data };
    personaleData[e.target.name] = e.target.value;
    setData(personaleData);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await api.post("login", {
      username: data.user,
      password: data.password,
    });
    if (response.data.status) {
      sessionStorage.setItem("logged", "ok");
      sessionStorage.setItem("Username", response.data.user[0].username);
      sessionStorage.setItem("Utente", response.data.user[0].id);
      sessionStorage.setItem("admin", response.data.user[0].admin);
      let responseAmministratori;
      if (response.data.user[0].admin === 2) {
        responseAmministratori = await api.get("amministratori");
      } else {
        responseAmministratori = await api.get(
          "amministratori/utente/" + sessionStorage.getItem("Utente")
        );
      }
      setAmministratore(responseAmministratori.data.data[0]?.id);
      sessionStorage.setItem("amministratore", responseAmministratori.data.data[0]?.id);
      sessionStorage.setItem(
        "colore",
        responseAmministratori.data.data[0]?.colore
      );
      navigate("/Home");
    } else {
      toast.error("Username o password errati");
    }
  };
  return (
    <>
      {/* <!-- component --> */}
      <ToastContainer />
      <div className="flex w-full h-screen flex-nowrap  relative">
        {/* Container */}
        <div
          className="lg:flex hidden justify-center align-middle bg-cover bg-center bg-no-repeat w-3/5 h-[calc(100vh - 4rem)] pl-2 bg-white "
          style={{ backgroundImage: `url(${loginImg})` }}
        ></div>
        <div className="flex justify-center align-middle flex-wrap w-full lg:w-2/5 bg-sinistri-blue">
          <div className="flex flex-col items-center justify-center mx-auto ">
            <div className="text-center text-4xl font-light text-white mb-10">
              Gestionale Sinistri
            </div>
            <form
              autoComplete="nope"
              onSubmit={handleLogin}
              className="w-full space-y-4 md:space-y-6 text-white"
            >
              <div>
                <span className="text-lg font-light">Username</span>
                <div className="relative">
                  <span className="absolute left-1 flex items-center pr-2 h-full">
                    <button
                      type="button"
                      //onClick={() => setShow(!show)}
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <HiOutlineUser className="text-2xl" />
                    </button>
                  </span>

                  <input
                    type="user"
                    name="user"
                    id="user"
                    className="w-full py-4 px-10 bg-sinistri-darkblue rounded-md placeholder:text-white"
                    placeholder="Username"
                    onChange={(e) => handleChange(e)}
                    value={data.user}
                  />
                </div>
              </div>
              <div>
                <span className="text-lg font-light">Password</span>
                <div className="relative">
                  <span className="absolute left-1 flex items-center pr-2 h-full">
                    <button
                      type="button"
                      //onClick={() => setShow(!show)}
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <HiOutlineLockClosed className="text-2xl" />
                    </button>
                  </span>
                  <span className="absolute right-0 flex items-center pr-2 h-full">
                    <button
                      type="button"
                      onClick={() => setShow(!show)}
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      {show ? (
                        <FaRegEye className="text-2xl" />
                      ) : (
                        <FaRegEyeSlash className="text-2xl" />
                      )}
                    </button>
                  </span>

                  <input
                    type={show ? "text" : "password"}
                    name="password"
                    id="password"
                    className="w-full py-4 px-10 rounded-md bg-sinistri-darkblue placeholder:text-white"
                    placeholder="Password"
                    onChange={(e) => handleChange(e)}
                    value={data.password}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full transition-all font-semibold rounded-full text-lg px-5 py-3 text-center shadow-lg hover:text-sinistri-blue hover:bg-slate-200  text-slate-200 bg-sinistri-lightgreen "
              >
                Login
              </button>
            </form>
          </div>
        </div>
        {/* <div className="w-[70vw] grid grid-cols-2 bg-white rounded-md relative shadow-md">
             <div className="col-span-1 bg-sinistri-blue  rounded-l-md"><img src={require("../assets/images/login-gestionale.jpg")} alt="" className="rounded-l-md h-full"/></div>
             <div className="col-span-1 md:mt-0 px-8 lg:px-6 py-12">
              <form
                autoComplete="nope"
                onSubmit={handleLogin}
                className="space-y-4 md:space-y-6 sm:p-1"
              >
                <div className="text-center text-4xl font-bold text-sinistri-blue mb-10">
                  Gestionale Sinistri
                </div>

                <div>
                  <input
                    type="user"
                    name="user"
                    id="user"
                    className="w-full py-4 px-8 bg-gray-200 rounded-md "
                    placeholder="Username"
                    onChange={(e) => handleChange(e)}
                    value={data.user}
                  />
                </div>
                
                <div className="relative">
                  <span className="absolute right-0 flex items-center pr-2 h-full">
                    <button
                      type="button"
                      onClick={() => setShow(!show)}
                      className="p-1 focus:outline-none focus:shadow-outline"
                    >
                      {show ? (
                        <FaRegEye className="text-2xl" />
                      ) : (
                        <FaRegEyeSlash className="text-2xl" />
                      )}
                    </button>
                  </span>
                  <input
                    type={show ? "text" : "password"}
                    name="password"
                    id="password"
                    className="w-full py-4 px-8 rounded-md bg-gray-200"
                    placeholder="Password"
                    onChange={(e) => handleChange(e)}
                    value={data.password}
                  />
                </div>
                
                <button
                  type="submit"
                  className="w-full transition-all font-semibold rounded-md text-lg px-5 py-3 text-center hover:bg-transparent border-slate-200 border text-slate-200 bg-sinistri-blue hover:text-slate-200 hover:bg-sinistri-darkblue "
                >
                  Login
                </button>
              </form>
            </div>
           
          </div>
        </div> */}
      </div>
    </>
  );
};
