import { useEffect, useState } from "react";
import {
  HiOutlineBookOpen,
  HiOutlineDocumentText,
  HiOutlineInformationCircle,
  HiOutlineListBullet,
  HiOutlinePaperAirplane,
  HiOutlinePaperClip,
  HiOutlinePlus,
  HiOutlineUser,
  HiOutlineUsers,
} from "react-icons/hi2";
/* import { TabPanel } from "./TabPanel"; */
import moment from "moment";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Input } from "../components/Input";
import { PiSignature } from "react-icons/pi";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import api from "../components/api/api";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Modal } from "../components/Modal";
import { HiOutlineClipboardCopy } from "react-icons/hi";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const InvioEmail = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState([]);
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const createTabs = location?.state?.selectedSinistri?.map((sinistro) => {
        let riferimento = sinistro?.riferimento;
        let data = sinistro?.data
          ? moment(sinistro?.data).format("YY") + " "
          : "";
        let condominio =
          sinistro?.condominio !== "" ? sinistro?.condominio + " " : " ";
        let danno = sinistro?.danno !== "" ? sinistro?.danno + " " : " ";
        let persone = sinistro?.persone ? sinistro?.persone : "";
        if (sinistro?.persone?.length >= 3) {
          persone = sinistro?.persone?.split(",")?.slice(0, 3);
        }
        return (
          <Tab
            label={
              riferimento +
              "/" +
              data +
              " " +
              condominio +
              " " +
              danno +
              " " +
              persone
            }
          />
        );
      });
      setTabs([...createTabs]);
    })();
  }, []);
  return (
    <div div className="page-container ">
      <div className="h-[60vh] grid grid-cols-2">
        <div className="col-span-2 flex h-full">
          {tabs?.length > 0 && (
            <div className="w-full">
              <Tabs
                value={value}
                textColor="inherit"
                variant="scrollable"
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                {tabs}
              </Tabs>
              {location?.state?.selectedSinistri?.map((sinistro, index) => {
                return (
                  <TabPanel value={value} index={index}>
                    <div className="w-full">
                      <EmailForm
                        type={location.state?.type}
                        sinistro={sinistro}
                        testo={location.state?.testo}
                      />
                    </div>
                  </TabPanel>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const EmailForm = ({ type, sinistro, testo }) => {
  const [openPopUpContatti, setOpenPopUpContatti] = useState(false);
  const [openPopUpInfo, setOpenPopUpInfo] = useState(false);
  const [emailPrincipaleAgenzia, setEmailPrincipaleAgenzia] = useState([]);
  const [amministratore, setAmministratore] = useState([]);
  const [multiDestinatari, setMultiDestinatari] = useState({
    perito: 0,
    agenzia: 0,
    danneggiati: 0,
  });

  const [impreseSinistro, setImpreseSinistro] = useState([]);
  const [franchigeSinistro, setFranchigeSinistro] = useState([]);
  const [personeInteressateSinistro, setPersoneInteressateSinistro] = useState(
    []
  );
  const [peritiSinistro, setPeritiSinistro] = useState([]);
  const [studioSinistro, setStudioSinistro] = useState(null);
  const [emailAgenziaSinistro, setEmailAgenziaSinistro] = useState([]);
  const [agenziaSinistro, setAgenziaSinistro] = useState(null);

  const [openListaReferenti, setOpenListaReferenti] = useState(false);
  const [referenti, setReferenti] = useState([]);

  const [sending, setSending] = useState(false);
  const [persone, setPersone] = useState([]);
  const [studiPeritali, setStudiPeritali] = useState([]);
  const [periti, setPeriti] = useState([]);
  const [mail, setMail] = useState({
    mittente: "",
    alias: "",
    password: "",
    destinatari: "",
    copia: "",
    copiaNascosta: "",
    oggetto: "",
    testo: "",
    firma: "",
  });
  const handleChangeMail = (e) => {
    setMail({ ...mail, [e.target.name]: e.target.value });
  };

  const formatNumber = (num) => {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "˙");
  };

  const copyValue = (val) => {
    navigator.clipboard.writeText(val);
    toast.success("Valore copiato!");
  };

  const handlePopUpInfo = async () => {
    setOpenPopUpInfo(true);
    let responseImpresa = await api.get("imprese/sinistro/" + sinistro.id);
    setImpreseSinistro(responseImpresa.data.data);
    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + sinistro.id
    );
    setPersoneInteressateSinistro(responseRelPersone.data.data);
    let responseGaranzie = await api.get(
      "polizze/franchigie/" + sinistro?.id_polizza
    );
    setFranchigeSinistro(responseGaranzie.data.data);
  };
  const handlePopUpContatti = async () => {
    setOpenPopUpContatti(true);
    let responseImpresa = await api.get("imprese/sinistro/" + sinistro.id);
    setImpreseSinistro(responseImpresa.data.data);
    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + sinistro.id
    );
    setPersoneInteressateSinistro(responseRelPersone.data.data);

    if (sinistro?.id_studio_peritale) {
      let responseRelStudi = await api.get(
        "studi_peritali/" + sinistro.id_studio_peritale
      );
      setStudioSinistro(responseRelStudi.data.data[0]);

      let responseRelPeriti = await api.get(
        "studi_peritali/periti/" + sinistro.id_studio_peritale
      );
      setPeritiSinistro(responseRelPeriti.data.data);
    }
    if (sinistro?.id_agenzia) {
      let responseAgenzia = await api.get("agenzie");
      const ag = responseAgenzia.data.data.filter(
        (el) => el.id === sinistro?.id_agenzia
      )[0];
      setAgenziaSinistro(ag);
      let responseEmailAgenzie = await api.get(
        "agenzie/email/" + sinistro.id_agenzia
      );
      setEmailAgenziaSinistro(responseEmailAgenzie.data.data);
    }
  };

  const handleOperReferenti = async () => {
    setOpenListaReferenti(true);
    let responseEmailAmministratori = await api.get(
      "amministratori/referente/" + sinistro.id_amministratore
    );
    setReferenti(
      responseEmailAmministratori.data.data?.filter(
        (ref) => ref.principale !== 1
      )
    );
  };
  const handleSendEmail = async () => {
    if (mail.destinatari) {
      setSending(true);
      try {
        await api.post("email/", {
          mittente: mail.mittente,
          alias: mail.alias,
          password: mail.password,
          destinatari: mail.destinatari,
          copia: mail?.copia,
          copiaNascosta: mail?.copiaNascosta,
          oggetto: mail.oggetto,
          testo: mail.testo,
          firma: mail.firma,
          allegati: mail?.allegati ? [mail?.allegati] : "",
          utente: sessionStorage.getItem("Username"),
          sinistro: sinistro.id,
          tipologia: type,
        });
        toast.success("Email inviata!");
      } catch (error) {
        toast.error("Errore durante l'invio!");
      }
      setSending(false);
    } else {
      toast.error("Campo Destinatari obbligatoro!");
    }
  };

  useEffect(() => {
    (async () => {
      let responseAgenzia = await api.get("agenzie");
      const ag = responseAgenzia.data.data.filter(
        (el) => el.id === sinistro?.id_agenzia
      )[0];
      setEmailPrincipaleAgenzia(ag?.email);
      let responseAmministratori = await api.get("amministratori");
      let amm = responseAmministratori.data.data?.filter(
        (el) => el.id === sinistro.id_amministratore
      )[0];
      setAmministratore(amm);
      let responseEmailAmministratori = await api.get(
        "amministratori/referente/" + amm?.id
      );
      let responseRelPersone = await api.get(
        "persone_interessate/sinistro/" + sinistro?.id
      );
      let maxThreePersone = responseRelPersone.data.data;
      if (responseRelPersone.data.data?.length >= 3) {
        maxThreePersone = responseRelPersone.data.data?.slice(0, 3);
      }
      setPersone(responseRelPersone.data.data);
      let responseUtenti = await api.get(
        "utenti/" + sessionStorage.getItem("Username")
      );

      let persOggetto = maxThreePersone?.map((pers) => " " + pers?.cognome);
      persOggetto = persOggetto?.join("");

      //OGGETTO MAIL
      let riferimento = sinistro?.riferimento;
      let data = sinistro?.data
        ? moment(sinistro?.data).format("YY") + " "
        : "";
      let condominio =
        sinistro?.condominio !== "" ? sinistro?.condominio + " " : " ";
      let ol_scheda =
        sinistro?.ol_scheda !== ""
          ? "scheda " + sinistro?.ol_scheda + " "
          : " ";
      let n_sinistro = sinistro?.n_sinistro ? sinistro?.n_sinistro + " " : "";
      let rif_perito = sinistro?.rif_perito ? sinistro?.rif_perito + " " : "";
      let danno = sinistro?.danno ? sinistro?.danno : " ";

      let oggetto =
        riferimento +
        "/" +
        data +
        condominio +
        ol_scheda +
        n_sinistro +
        persOggetto +
        " " +
        danno +
        rif_perito;
      let destinatari = "";
      let copia = "";
      let testo_aggintivo = "";
      let allegato = "";
      const urlParams = new URLSearchParams();
      const urlParamsInviata = new URLSearchParams();
      urlParams.append("id", sinistro?.id);
      urlParamsInviata.append("id", sinistro?.id);
      let responseEmail;
      //IN BASE ALLA TIPOLOGIA DI EMAIL SETTO DESTINATARI DIFFERENTI
      switch (type) {
        case "Amministrazione":
          destinatari = responseEmailAmministratori.data.data
            ?.filter((responsabili) => responsabili.principale === 1)
            .map((res) => res.email);
          break;
        case "Pratica":
          if (sinistro?.id_perito) {
            let responsePeriti = await api.get(
              "studi_peritali/periti/" + sinistro?.id_studio_peritale
            );
            setPeriti(responsePeriti.data.data);
          }
          let responseStudiPeritali = await api.get(
            "studi_peritali/" + sinistro?.id_studio_peritale
          );
          setStudiPeritali(responseStudiPeritali.data.data);

          break;
        /* case "Invio Documentazione":
          let responsePeritiDoc = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          //CONTROLLO IL PERITO DI RIFERIMENTO
          const emailPeritoDoc = responsePeritiDoc?.data.data.filter(
            (p) => sinistro.id_perito === p.id
          )[0]?.email;
          if (emailPerito) {
            destinatari = emailPeritoDoc;
          } else {
            destinatari = responsePeritiDoc.data.data[0]?.email_studio;
          }
          break; */
        case "Sinistro":
          let responseDenunce = await api.get(
            "denunce/sinistro/" + sinistro.id
          );
          if (responseDenunce.data.data?.length > 0) {
            allegato = responseDenunce.data.data[0]?.id_file;
          }
          urlParams.append("tipologia", "Sinistro");
          responseEmail = await api.get(`email/sinistro/`, {
            params: urlParams,
          });
          if (responseEmail.data.data?.length > 0) {
            testo_aggintivo =
              testo_aggintivo + `\n\nPrecedenti sollecitazioni:`;
          }
          responseEmail.data.data?.map((email) => {
            testo_aggintivo =
              testo_aggintivo +
              `\n-Data ${moment(email?.data).format("DD-MM-YYYY HH:mm:ss")}`;
          });
          destinatari = ag?.email;
          break;
        case "Invio Atto":
          let responsePeritii = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          //CONTROLLO IL PERITO DI RIFERIMENTO
          const emailPerito = responsePeritii?.data.data.filter(
            (p) => sinistro.id_perito === p.id
          )[0]?.email;
          if (emailPerito) {
            destinatari = emailPerito;
          } else {
            destinatari = responsePeritii.data.data[0]?.email_studio;
          }
          urlParams.append("tipologia", "Invio Documentazione");
          responseEmail = await api.get(`email/sinistro/`, {
            params: urlParams,
          });
          if (responseEmail.data.data?.length > 0) {
            testo_aggintivo = testo_aggintivo + `\n\nDocumentazione inviata:`;
          }
          responseEmail.data.data?.map((email) => {
            testo_aggintivo =
              testo_aggintivo +
              `\n-Data ${moment(email?.data).format("DD-MM-YYYY HH:mm:ss")}`;
          });
          break;
        case "Invio Proposta":
          //OFFERTA
          let responseLiquidazioni = await api.get("offerte/" + sinistro?.id);
          //AMMINISTRATORI
          destinatari = responseEmailAmministratori.data.data
            ?.filter((responsabili) => responsabili.principale === 1)
            .map((res) => res.email);
          let responseFranchigie = await api.get(
            "polizze/franchigie/sinistro/" + sinistro?.id
          );
          //FRANCHIGIA
          if (responseFranchigie.length > 0) {
            let franchigia = responseFranchigie.data.data.filter(
              (el) => el.id_garanzia === sinistro?.id_tipologia_sinistro
            );
            testo_aggintivo = `\n\nFranchigia: ${
              franchigia[0]?.id_garanzia === 1 ? "importo RG" : "importo "
            } ${franchigia[0]?.valore ? franchigia[0]?.valore : ""} ${
              franchigia[0]?.doppio_valore
                ? "importo AC " + franchigia[0]?.doppio_valore
                : ""
            } ${
              franchigia[0]?.minimo ? "minimo " + franchigia[0]?.minimo : ""
            } ${
              franchigia[0]?.percentuale
                ? "percentuale " + franchigia[0]?.percentuale + "%"
                : ""
            }`;
          }
          //PERSONE
          let responseRelPersone = await api.get(
            "persone_interessate/sinistro/" + sinistro?.id
          );

          const sommaTotale = responseRelPersone.data.data?.reduce(
            (sum, item) => sum + item.liquidazione,
            0
          );
          if (responseRelPersone.data.data?.length > 0) {
            testo_aggintivo =
              testo_aggintivo +
              `\n\nTotale liquidazioni Persone Interessate: ${sommaTotale}${
                responseRelPersone.data.data?.length > 0 && "€"
              }`;
          }
          responseRelPersone.data.data
            .filter((el) => el?.liquidazione !== 0)
            ?.map((persona) => {
              testo_aggintivo =
                testo_aggintivo +
                `\n-${persona?.cognome} ${persona?.nome} importo liquidato: ${
                  persona?.liquidazione ? persona.liquidazione + "€" : ""
                }`;
            });
          //IMPORTO RESTANTE
          const ultimaOfferata = responseLiquidazioni.data.data[0];
          let restante = ultimaOfferata?.offerta - sommaTotale;
          testo_aggintivo =
            testo_aggintivo + `\n${restante && "-Rimanente " + restante + "€"}`;
          testo_aggintivo =
            testo_aggintivo + `\n${restante && "-Rimanente " + restante + "€"}`;
          //IMPRESE
          let responseImpresa = await api.get(
            "imprese/sinistro/" + sinistro.id
          );
          let filterFatture = responseImpresa.data.data?.filter(
            (fatt) => fatt.fattura
          );
          if (filterFatture?.length > 0) {
            testo_aggintivo =
              testo_aggintivo +
              `\n\nTotale costi Imprese: ${filterFatture?.reduce(
                (sum, item) => sum + item.fattura,
                0
              )}${filterFatture?.length > 0 && "€"}`;
            filterFatture?.map((impresa) => {
              testo_aggintivo =
                testo_aggintivo +
                `\n-${impresa?.impresa} -${
                  impresa?.n_fattura ? " n°" + impresa?.n_fattura + " -" : ""
                } fattura: ${impresa?.fattura ? impresa?.fattura + "€" : ""}`;
            });
          }
          break;
        case "Sollecito Atto Firmato":
          destinatari = responseEmailAmministratori.data.data
            ?.filter((responsabili) => responsabili.principale === 1)
            .map((res) => res.email);
          urlParams.append("tipologia", "Sollecito Atto Firmato");
          responseEmail = await api.get(`email/sinistro/`, {
            params: urlParams,
          });
          urlParamsInviata.append("tipologia", "Atto Da Firmare");
          let responseEmailInviata = await api.get(`email/sinistro/`, {
            params: urlParamsInviata,
          });
          if (responseEmailInviata.data.data?.length > 0) {
            allegato = responseEmailInviata.data.data[0]?.id_file;
          }
          if (responseEmail.data.data?.length > 0) {
            testo_aggintivo =
              testo_aggintivo + `\n\nPrecedenti sollecitazioni:`;
          }
          responseEmail.data.data?.map((email) => {
            testo_aggintivo =
              testo_aggintivo +
              `\n-Data ${moment(email?.data).format("DD-MM-YYYY HH:mm:ss")}`;
          });
          break;
        default:
          break;
      }
      setMail({
        ...mail,
        mittente: amm?.email ? amm.email : "",
        alias: amm?.alias_check === 1 ? amm?.alias : "",
        password: amm?.password_email ? amm.password_email : "",
        destinatari: destinatari,
        copia: copia,
        copiaNascosta: responseUtenti.data?.data[0]?.copia_nascosta,
        firma: amm.firma ? amm.firma : "",
        oggetto: oggetto,
        testo: testo + " " + testo_aggintivo,
        allegati: allegato,
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let agenzia;
      let perito;
      let danneggiati;
      if (multiDestinatari.agenzia === 1) {
        emailPrincipaleAgenzia === "" &&
          toast.warning("Nessun indirizzo email presente!");
        agenzia = emailPrincipaleAgenzia ? emailPrincipaleAgenzia + "," : "";
      } else {
        agenzia = "";
      }
      if (multiDestinatari.perito === 1) {
        let destinatari;
        if (
          sinistro?.id_perito &&
          periti?.filter((p) => sinistro.id_perito === p.id)[0]?.email
        ) {
          //se il perito è stato selezionato
          destinatari = periti?.filter((p) => sinistro.id_perito === p.id)[0]
            ?.email;
        } else {
          //se il perito non esiste oppure se esiste e non ha l'indirizzo email
          toast.warning("Nessun indirizzo email del perito!");
          if (sinistro?.id_studio_peritale && studiPeritali[0]?.email) {
            destinatari = studiPeritali[0]?.email
              ? studiPeritali[0]?.email + ","
              : "";
          } else {
            destinatari = "";
            toast.warning("Nessun indirizzo email dello studio peritale!");
          }
        }
        perito = destinatari;
      } else {
        perito = "";
      }
      if (multiDestinatari.danneggiati === 1) {
        let emailPersone = [];
        persone?.map((persona) => {
          if (persona.email) {
            return (emailPersone = [...emailPersone, persona.email]);
          } else {
            toast.warning(
              "Danneggiato " + persona?.cognome + " non ha l'indirizzo email!"
            );
            return (emailPersone = [...emailPersone]);
          }
        });
        if (emailPersone?.length !== 0) {
          danneggiati = emailPersone;
        } else {
          danneggiati = "";
          toast.warning("Nessun indirizzo email presente!");
        }
      } else {
        danneggiati = "";
      }
      setMail({
        ...mail,
        destinatari: agenzia + perito + danneggiati,
      });
    })();
  }, [multiDestinatari]);

  return (
    <div className="grid grid-cols-2 gap-2 h-full px-2">
      <div className="flex col-span-2 justify-between">
        <div className=""></div>
        <div className=" text-2xl text-center">{type}</div>
        <div className="flex gap-2 ">
          <div
            className="icon-container-green group"
            onClick={handlePopUpContatti}
          >
            <HiOutlineBookOpen className="icon-green" />
          </div>
          <div className="icon-container-green group" onClick={handlePopUpInfo}>
            <HiOutlineInformationCircle className="icon-green" />
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <Input
          label={"Mittente"}
          type="text"
          name="mittente"
          onChange={handleChangeMail}
          value={mail?.mittente}
          icon={<HiOutlineUser className="text-2xl" />}
        />
      </div>
      {mail?.alias && (
        <div className="col-span-2">
          <Input
            label={"Alias"}
            type="text"
            name="alias"
            onChange={handleChangeMail}
            value={mail?.alias}
            icon={<HiOutlineUser className="text-2xl" />}
          />
        </div>
      )}
      {type === "Pratica" && (
        <>
          <div className="col-span-2 flex gap-3">
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="perito"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.perito === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    perito: multiDestinatari?.perito === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Perito
              </span>
            </div>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="agenzia"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.agenzia === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    agenzia: multiDestinatari?.agenzia === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Agenzia
              </span>
            </div>
            <div className="flex items-center h-14 py-4 px-3 bg-gray-200 rounded-md relative cursor-pointer">
              <input
                type="checkbox"
                name="danneggiati"
                className="h-6 w-6  placeholder:text-black"
                checked={multiDestinatari?.danneggiati === 1}
                onChange={() =>
                  setMultiDestinatari({
                    ...multiDestinatari,
                    danneggiati: multiDestinatari?.danneggiati === 1 ? 0 : 1,
                  })
                }
              />
              <span
                htmlFor="default-checkbox"
                className="text-base font-normal ml-2"
              >
                Danneggiati
              </span>
            </div>
          </div>
        </>
      )}
      <div className="col-span-2 w-full flex items-end gap-2">
        <Input
          label={"Destinatari"}
          type="text"
          name="destinatari"
          onChange={handleChangeMail}
          value={mail?.destinatari}
          icon={<HiOutlineUser className="text-2xl" />}
        />
        {type === "Amministrazione" && (
          <div
            className="icon-container-green group"
            onClick={handleOperReferenti}
          >
            <HiOutlineUsers className="icon-green" />
          </div>
        )}
      </div>
      <div className="col-span-2">
        <Input
          label={"In Copia"}
          type="text"
          name="copia"
          onChange={handleChangeMail}
          value={mail?.copia}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div>
      <div className="col-span-2">
        <Input
          label={"In Copia Nascosta"}
          type="text"
          name="copiaNascosta"
          onChange={handleChangeMail}
          value={mail?.copiaNascosta}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div>
      <div className="col-span-2">
        <Input
          label={"Oggetto"}
          type="text"
          name="oggetto"
          onChange={handleChangeMail}
          value={mail?.oggetto}
          icon={<HiOutlineUsers className="text-2xl" />}
        />
      </div>
      {mail?.allegati && (
        <div className="col-span-2 ">
          <span className="text-lg font-normal">Allegato:</span>
          <div className="relative">
            <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
              <HiOutlinePaperClip className="text-2xl" />
            </span>
            <div className="input flex items-center flex-wrap">
              <div
                className="underline"
                onClick={() =>
                  window.open(
                    `https://drive.google.com/file/d/${mail?.allegati}/view?usp=drivesdk`,
                    "_blank"
                  )
                }
              >
                File.pdf
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-2 ">
        <span className="text-lg font-normal">Testo</span>
        <div className="relative">
          <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
            <HiOutlineDocumentText className="text-2xl" />
          </span>

          <textarea
            rows={5}
            type="text"
            name="testo"
            className="input flex items-center"
            placeholder="Inserisci Testo"
            onChange={handleChangeMail}
            value={mail?.testo}
          />
        </div>
      </div>
      <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-6">
        <>
          {sending ? (
            <div
              className="icon-container-green group "
              onClick={() => toast.warning("Invio in corso!")}
            >
              <AiOutlineLoading3Quarters className="icon-green animate-spin" />
            </div>
          ) : (
            <div
              className="icon-container-green h-min group "
              onClick={handleSendEmail}
            >
              <HiOutlinePaperAirplane className="icon-green" />
            </div>
          )}
        </>
      </div>
      <Modal
        open={openListaReferenti}
        handleClose={async () => {
          setOpenListaReferenti(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Lista Referenti</h2>
        <div className="w-full  mt-6 h-80">
          <DataGridPro
            rows={referenti}
            columns={[
              {
                field: "email",
                headerName: "Email",
                flex: 1,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "nome",
                headerName: "Nome",
                width: 150,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
              {
                field: "telefono",
                headerName: "Telefono",
                width: 100,
                editable: false,
                align: "center",
                headerAlign: "center",
              },
            ]}
            getRowId={(row) => row.id}
            disableRowSelectionOnClick
          />
        </div>
      </Modal>
      <Modal
        open={openPopUpContatti}
        handleClose={async () => {
          setOpenPopUpContatti(false);
        }}
      >
        <h2 className="text-2xl text-center mb-2">Contatti</h2>
        <div className="w-full flex flex-col">
          {personeInteressateSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Danneggiati:</div>
              {personeInteressateSinistro.map((pers) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {pers?.cognome} {pers?.nome}: <b>{pers?.email}</b>
                  {pers?.email && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() => copyValue(pers.email)}
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {agenziaSinistro && (
            <div className="w-full flex flex-col">
              <div className="text-lg font-semibold">Agenzia:</div>
              <div className="px-2 py-1 flex items-center gap-2">
                {agenziaSinistro?.nome}: <b>{agenziaSinistro?.email}</b>
                {agenziaSinistro?.email && (
                  <div
                    className="icon-container-green-min group"
                    onClick={() => copyValue(agenziaSinistro?.email)}
                  >
                    <HiOutlineClipboardCopy className="icon-green-min" />
                  </div>
                )}
              </div>
            </div>
          )}
          {emailAgenziaSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Email Agenzia:</div>
              {emailAgenziaSinistro.map((email) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  <b>{email?.email}</b>
                  {email?.email && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() => copyValue(email.email)}
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {studioSinistro && (
            <div className="w-full flex flex-col">
              <div className="text-lg font-semibold">Studio Peritale:</div>
              <div className="px-2 py-1 flex items-center gap-2">
                {studioSinistro?.nome}: <b>{studioSinistro?.email}</b>
                {studioSinistro?.email && (
                  <div
                    className="icon-container-green-min group"
                    onClick={() => copyValue(studioSinistro?.email)}
                  >
                    <HiOutlineClipboardCopy className="icon-green-min" />
                  </div>
                )}
              </div>
            </div>
          )}
          {peritiSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Periti:</div>
              {peritiSinistro.map((perito) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {perito?.cognome} {perito?.nome}: <b>{perito?.email}</b>
                  {perito?.email && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() => copyValue(perito.email)}
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {impreseSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Imprese:</div>
              {impreseSinistro.map((impresa) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {impresa?.impresa}: <b>{impresa?.email}</b>
                  {impresa?.email && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() => copyValue(impresa.email)}
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </Modal>
      <Modal
        open={openPopUpInfo}
        handleClose={async () => {
          setOpenPopUpInfo(false);
        }}
      >
        <h2 className="text-2xl text-center mb-2">Informazioni</h2>
        <div className="w-full flex flex-col">
          {personeInteressateSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Danneggiati:</div>
              {personeInteressateSinistro.map((pers) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {pers?.cognome} {pers?.nome}: <b>{pers?.telefono}</b>
                  {pers?.telefono && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() =>
                        copyValue(
                          pers?.cognome +
                            " " +
                            pers?.nome +
                            ": " +
                            pers.telefono
                        )
                      }
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {impreseSinistro?.length > 0 && (
            <>
              <div className="text-lg font-semibold">Imprese:</div>
              {impreseSinistro.map((impresa) => (
                <div className="px-2 py-1 flex items-center gap-2">
                  {impresa?.impresa} - n° fattura: <b>{impresa?.n_fattura}</b>
                  {impresa?.n_fattura && (
                    <div
                      className="icon-container-green-min group"
                      onClick={() =>
                        copyValue(
                          impresa?.impresa +
                            " - n° fattura: " +
                            impresa?.n_fattura
                        )
                      }
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          {sinistro?.condominio && (
            <>
              <div className="text-lg font-semibold">Condominio:</div>
              <div className="px-2 py-1 flex items-center gap-2">
                {sinistro.condominio} - n° polizza: <b>{sinistro?.n_polizza}</b>
                {sinistro?.n_polizza && (
                  <div
                    className="icon-container-green-min group"
                    onClick={() =>
                      copyValue(
                        sinistro.condominio +
                          " - n° polizza: " +
                          sinistro?.n_polizza
                      )
                    }
                  >
                    <HiOutlineClipboardCopy className="icon-green-min" />
                  </div>
                )}
              </div>
              {sinistro?.valore_assicurato && (
                <>
                  <div className="px-2 flex items-center gap-2">
                    Valore assicurato:{" "}
                    <b>{formatNumber(sinistro.valore_assicurato)}€</b>
                    <div
                      className="icon-container-green-min group"
                      onClick={() =>
                        copyValue(
                          "Valore assicurato: " +
                            formatNumber(sinistro.valore_assicurato) +
                            "€"
                        )
                      }
                    >
                      <HiOutlineClipboardCopy className="icon-green-min" />
                    </div>
                  </div>
                </>
              )}
              {franchigeSinistro?.length > 0 && (
                <>
                  <div className="text-lg font-semibold">Franchigie:</div>
                  {franchigeSinistro.map((franchigia) => {
                    let frase = franchigia?.garanzia + ": ";
                    frase += franchigia?.valore
                      ? ` valore ${franchigia?.doppio_valore ? "RG " : ""}` + franchigia?.valore
                      : "";
                    frase += franchigia?.doppio_valore
                      ? " valore AC " + franchigia.doppio_valore
                      : "";
                    frase +=
                      franchigia?.franchigia_scoperto === 1 &&
                      franchigia?.percentuale
                        ? " percentuale " + franchigia.percentuale + "%"
                        : "";
                    frase +=
                      franchigia?.franchigia_scoperto === 1 &&
                      franchigia?.minimo
                        ? " minimo " + franchigia.minimo
                        : "";

                    return (
                      <div className="px-2 py-1 flex items-center gap-2">
                        {frase}
                        <div
                          className="icon-container-green-min group"
                          onClick={() => copyValue(frase)}
                        >
                          <HiOutlineClipboardCopy className="icon-green-min" />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};
